import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-v-l tac" }
const _hoisted_2 = {
  key: 1,
  class: "m-v-l tac"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!

  return (!_ctx.disabled)
    ? (_openBlock(), _createBlock(_component_ion_infinite_scroll, {
        key: 0,
        onIonInfinite: _cache[1] || (_cache[1] = ($event: any) => (_ctx.more($event)))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_chip, {
              color: "medium",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.more()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: "information" }),
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("加载更多...")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_ion_infinite_scroll_content, { "loading-spinner": "none" })
        ]),
        _: 1
      }))
    : (_ctx.cnt > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ion_chip, { color: "medium" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: "information" }),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("没有了")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
}