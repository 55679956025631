import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["name", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!

  return (_openBlock(), _createBlock(_component_ion_input, {
    placeholder: _ctx.i18n.inputPrompt,
    type: "number",
    min: "0",
    step: "0.01",
    modelValue: _ctx.fen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fen) = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("input", {
        type: "hidden",
        name: _ctx.name,
        value: _ctx.fen || _ctx.fen === 0 ? Math.round(_ctx.fen * 100) : null
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["placeholder", "modelValue"]))
}