import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "xx-large bolder" }
const _hoisted_2 = { class: "smaller" }
const _hoisted_3 = { class: "smaller" }
const _hoisted_4 = { class: "smaller tac" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createBlock(_component_ion_footer, { translucent: true }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, { color: "dark" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-lg": "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_toolbar, { color: "dark" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_buttons, { slot: "start" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_thumbnail, {
                                class: "ion-margin-vertical",
                                "router-link": "/ow"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_img, { src: "/assets/icon/icon.png" })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_title, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.i18n.app.name), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createElementVNode("p", _hoisted_2, "主管单位: " + _toDisplayString(_ctx.i18n.company.supervisor), 1),
                      _createElementVNode("p", _hoisted_3, "运营单位: " + _toDisplayString(_ctx.i18n.company.name), 1),
                      _cache[0] || (_cache[0] = _createElementVNode("p", { class: "smaller" }, [
                        _createTextVNode("电话: "),
                        _createElementVNode("a", { href: "tel:010-82803859" }, "010-82803859")
                      ], -1)),
                      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "smaller" }, [
                        _createTextVNode("邮箱: "),
                        _createElementVNode("a", { href: "mailto:postmaster@cnzjrh.cn" }, "postmaster@cnzjrh.cn")
                      ], -1)),
                      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "smaller" }, "地址: 北京市海淀区花园路7号新时代大厦7层", -1))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-lg": "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_toolbar, { color: "dark" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_title, null, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode("友情链接")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_grid, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_row, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.friendLinks, (links, title, i) => {
                                return (_openBlock(), _createBlock(_component_ion_col, {
                                  size: "12",
                                  "size-lg": "6",
                                  key: title
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_button, {
                                      color: "light",
                                      expand: "block",
                                      class: "ion-text-start",
                                      id: `friend-link-trigger${_ctx.friendLinkPostfix}${i}`
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(title), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["id"]),
                                    _createVNode(_component_ion_popover, {
                                      trigger: `friend-link-trigger${_ctx.friendLinkPostfix}${i}`,
                                      "dismiss-on-select": ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_content, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_list, null, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(links, (link, name) => {
                                                  return (_openBlock(), _createBlock(_component_ion_item, {
                                                    button: "",
                                                    detail: "",
                                                    key: name,
                                                    target: "_blank",
                                                    href: link
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(name), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["href"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["trigger"])
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_toolbar, { color: "medium" }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_4, [
            _createTextVNode(" Copyright © " + _toDisplayString(_ctx.i18n.company.name) + " All Rights Reserved. ", 1),
            _cache[4] || (_cache[4] = _createElementVNode("a", {
              href: "https://beian.miit.gov.cn",
              target: "_blank"
            }, "京ICP备18039731号-1", -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}