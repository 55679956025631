import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { IonicVue, isPlatform } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/style.scss'

import '@/h/prototype'
import { session, h, l, i18n, conf, portal, portalName } from '@/h'

const ionicConfig = () => {
  const config = {}
  if (isPlatform('ios')) Object.assign(config, {
    backButtonText: i18n.back
  })
  return config
}
const app = createApp(App).use(IonicVue, ionicConfig()).use(router)

router.isReady().then(() => {
  app.mount('#app')
})

app.mixin({
  data() {
    return {
      session, h, l, i18n, conf
    }
  },
  methods: {
    portal, portalName
  }
})

import MbrPageHeader from '@/components/mbr/page_header.vue'
app.component('MbrPageHeader', MbrPageHeader)
import OpPageHeader from '@/components/op/page_header.vue'
app.component('OpPageHeader', OpPageHeader)
import SerpPageHeader from '@/components/serp/page_header.vue'
app.component('SerpPageHeader', SerpPageHeader)
import OwPageHeader from '@/components/ow_page_header.vue'
app.component('OwPageHeader', OwPageHeader)
import OwPageFooter from '@/components/ow_page_footer.vue'
app.component('OwPageFooter', OwPageFooter)
import RmbInput from '@/components/rmb_input.vue'
app.component('RmbInput', RmbInput)
import InfiniteScroll from '@/components/infinite_scroll.vue'
app.component('InfiniteScroll', InfiniteScroll)

import {
  IonPage,
  IonHeader,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonContent,
  IonMenu,
  IonMenuToggle,
  IonGrid,
  IonRow,
  IonCol,
  IonAvatar,
  IonThumbnail,
  IonButtons,
  IonButton,
  IonBackButton,
  IonLabel,
  IonNote,
  IonChip,
  IonIcon,
  IonImg,
  IonText,
  IonInput,
  IonTextarea,
  IonDatetime,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonRadio,
  IonRadioGroup,
  IonListHeader,
  IonList,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonBadge,
  IonSlides,
  IonSlide,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonPopover,
} from '@ionic/vue'
app.component('IonPage', IonPage)
app.component('IonHeader', IonHeader)
app.component('IonFooter', IonFooter)
app.component('IonTitle', IonTitle)
app.component('IonToolbar', IonToolbar)
app.component('IonContent', IonContent)
app.component('IonMenu', IonMenu)
app.component('IonMenuToggle', IonMenuToggle)
app.component('IonGrid', IonGrid)
app.component('IonRow', IonRow)
app.component('IonCol', IonCol)
app.component('IonAvatar', IonAvatar)
app.component('IonThumbnail', IonThumbnail)
app.component('IonButtons', IonButtons)
app.component('IonButton', IonButton)
app.component('IonBackButton', IonBackButton)
app.component('IonLabel', IonLabel)
app.component('IonNote', IonNote)
app.component('IonChip', IonChip)
app.component('IonIcon', IonIcon)
app.component('IonImg', IonImg)
app.component('IonText', IonText)
app.component('IonInput', IonInput)
app.component('IonTextarea', IonTextarea)
app.component('IonDatetime', IonDatetime)
app.component('IonSelect', IonSelect)
app.component('IonSelectOption', IonSelectOption)
app.component('IonCheckbox', IonCheckbox)
app.component('IonRadio', IonRadio)
app.component('IonRadioGroup', IonRadioGroup)
app.component('IonListHeader', IonListHeader)
app.component('IonList', IonList)
app.component('IonItem', IonItem)
app.component('IonCard', IonCard)
app.component('IonCardHeader', IonCardHeader)
app.component('IonCardTitle', IonCardTitle)
app.component('IonCardSubtitle', IonCardSubtitle)
app.component('IonCardContent', IonCardContent)
app.component('IonBadge', IonBadge)
app.component('IonSlides', IonSlides)
app.component('IonSlide', IonSlide)
app.component('IonModal', IonModal)
app.component('IonSegment', IonSegment)
app.component('IonSegmentButton', IonSegmentButton)
app.component('IonInfiniteScroll', IonInfiniteScroll)
app.component('IonInfiniteScrollContent', IonInfiniteScrollContent)
app.component('IonSearchbar', IonSearchbar)
app.component('IonPopover', IonPopover)

import { addIcons } from 'ionicons'
import {
  menu, person, people, exit, enter, list, add, remove, create, trash, home, cart, call, close, information, warning, checkbox, checkmark, location, reload, male, female, options, help, pricetag, cafe, cash, storefront, gift, link, cube, document, newspaper, mail, copy, pin,
  maleFemale,
  lockClosed,
  chevronDown,
  caretForward,
  logoWechat,
  logoAlipay,
  ellipsisHorizontal,
} from 'ionicons/icons'
addIcons({
  menu, person, people, exit, enter, list, add, remove, create, trash, home, cart, call, close, information, warning, checkbox, checkmark, location, reload, male, female, options, help, pricetag, cafe, cash, storefront, gift, link, cube, document, newspaper, mail, copy, pin,
  malefemale: maleFemale,
  lockclosed: lockClosed,
  chevrondown: chevronDown,
  caretforward: caretForward,
  logowechat: logoWechat,
  logoalipay: logoAlipay,
  ellipsishorizontal: ellipsisHorizontal,
})
