<template>
  <ion-infinite-scroll @ionInfinite="more($event)" v-if="!disabled">
    <div class="m-v-l tac">
      <ion-chip color="medium" @click="more()">
        <ion-icon icon="information"></ion-icon>
        <ion-label>加载更多...</ion-label>
      </ion-chip>
    </div>
    <ion-infinite-scroll-content loading-spinner="none"></ion-infinite-scroll-content>
  </ion-infinite-scroll>
  <div class="m-v-l tac" v-else-if="cnt > 0">
    <ion-chip color="medium">
      <ion-icon icon="information"></ion-icon>
      <ion-label>没有了</ion-label>
    </ion-chip>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { axios } from '@/h'

export default defineComponent({
  props: {
    url: {
      default: null
    },
    d: {
      default: {}
    },
    k: {
      default: ''
    },
    q: {
      default: {}
    }
  },
  setup(props) {
    const cnt = computed(() => (props.d as any)[props.k].length)
    const disabled = ref(false)
    const route = useRoute()
    const more = (ev?: CustomEvent) => {
      axios.get(props.url || route.path, {
        params: Object.assign(Object.assign({}, props.q), { offset: cnt.value })
      }).then((response) => {
        const newList = response.data[props.k]
        ;(props.d as any)[props.k].push(...newList)

        if (ev) (ev.target as any).complete()
        if (newList.length <= 0) disabled.value = true
      })
    }

    watch(props.d, () => disabled.value = cnt.value <= 0)

    return {
      cnt,
      disabled,
      more
    }
  }
})
</script>

<style scoped>
/* ion-infinite-scroll:not(.infinite-scroll-loading) {
  padding-bottom: 100vh;
} */
</style>
