import axios from 'axios'
import router from '@/router'
import { portal, session, toast } from '@/h'

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL || 'http://localhost:3000'
axios.defaults.withCredentials = true
axios.defaults.xsrfCookieName = 'CSRF-Token'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.interceptors.request.use((config) => {
  const query = router.currentRoute.value.query
  if (Object.keys(query).length > 0) {
    config.params = Object.assign(Object.assign({}, query), config.params || {})
  }
  document.documentElement.setAttribute('loading', 'true')
  return config
}, function (error) {
  return Promise.reject(error)
})
axios.interceptors.response.use((response) => {
  if (Object.prototype.hasOwnProperty.call(response.headers, 'x-csrf-token')) {
    axios.defaults.headers.common['X-CSRF-Token'] = response.headers['x-csrf-token']
  }
  document.documentElement.removeAttribute('loading')
  return response
}, function (error) {
  if (error.response.status === 401) {
    session.signOut()
    const routeData = {
      name: `${portal()}-signin`,
      query: { return_to: router.currentRoute.value.fullPath }
    }
    error.response.config.method === 'get' ? router.replace(routeData) :router.push(routeData)
  } else if (error.response.status === 403) {
    location.replace('/unauthorized')
  // } else if (error.response.status === 422) {
  } else {
    toast(error.response.data)
  }
  document.documentElement.removeAttribute('loading')
  return Promise.reject(error)
})

export default axios
