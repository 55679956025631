<template>
  <ion-header :translucent="true">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button :default-href="backTo" v-if="backTo"></ion-back-button>
      </ion-buttons>
      <ion-title>{{ pt }}</ion-title>
      <ion-buttons slot="end">
        <slot name="end"></slot>
      </ion-buttons>
    </ion-toolbar>
    <slot></slot>
  </ion-header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { portalName } from '@/h'

export default defineComponent({
  props: {
    pt: {
      type: String,
      default: portalName()
    },
    backTo: {
      type: String,
      default: '/mbr'
    }
  }
})
</script>

<style scoped>
</style>
