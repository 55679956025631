<template>
  <ion-header :translucent="true">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button :default-href="backTo" v-if="backTo"></ion-back-button>
        <ion-menu-toggle menu="first">
          <ion-button>
            <ion-icon icon="menu"></ion-icon>
          </ion-button>
        </ion-menu-toggle>
      </ion-buttons>
      <ion-title>{{ pt }}</ion-title>
      <ion-buttons slot="end">
        <ion-button :title="i18n.profile" router-link="/op/user">
          <ion-icon icon="person"></ion-icon>
        </ion-button>
        <ion-button @click="signOut()" :title="i18n.sign_out">
          <ion-icon icon="exit" slot="start"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { portalName, axios, session } from '@/h'

export default defineComponent({
  props: {
    pt: {
      type: String,
      default: portalName()
    },
    backTo: {
      type: String,
      default: '/op/home'
    }
  },
  setup() {
    const router = useRouter()
    const signOut = function () {
      axios.delete('/op/visit/signins/0').then(() => {
        session.signOut()
        router.replace('/op/welcome')
      })
    }
    return {
      signOut
    }
  }
})
</script>

<style scoped>
</style>
