import { ref } from 'vue'

const i18n = {
  app: {
    name: '中军网',
    slogan: '中军网',
    invitation: '中军网'
  },
  company: {
    name: '中军融合（北京）文化传播有限公司',
    supervisor: '《中国军转民》杂志社',
    address: '北京市海淀区'
  },
  portals: {
    mbr: '会员',
    op: '运营后台',
    serp: '商户 ERP'
  },
  welcome: '欢迎',
  sign_in: '登录',
  sign_up: '注册',
  sign_out: '退出',
  home: '首页',
  me: '我',
  cart: '购物车',
  profile: '个人中心',
  index: '列表',
  show: '详情',
  new: '新建',
  edit: '编辑',
  create: '创建',
  update: '更新',
  destroy: '删除',
  submit: '提交',
  close: '关闭',
  cancel: '取消',
  ok: '确定',
  done: '完成',
  back: '返回',
  forward: '前进',
  continue: '继续',
  reload: '刷新',
  view: '查看',
  more: '更多',
  all: '全部',
  none: '无',
  optional: '可选',
  confirmTip: '确定继续吗？',
  inputPrompt: '请输入',
  selectPrompt: '请选择',
  select: '选择',
  true: '是',
  false: '否',
  default: '默认',
  customer_service: '客服',
  created_at: '创建时间',
  invite: '邀请',
  go_shopping: '去逛逛',
  switch: '切换',
  tip: '提示',
  qty: '数量',
  search: '搜索',
  query: '查询',
  about: '关于我们',
  contact: '联系我们',
  mall: '交易平台',
  magazine: '中国军转民杂志',
  t(key: string) {
    const keys = key.split('.'), lastKey = keys[keys.length - 1]
    return keys.length > 1 && keys.reduce((m: any, s: string) => m[s], this.models) || (this.models.attributes as any)[lastKey] || (this as any)[lastKey]
  },
  m(key: string) {
    return i18n.t(`${key}._`)
  },
  models: {
    attributes: {
      _: '模型',
      id: 'ID',
      name: '名称',
      mobile: '手机号',
      image: '图片',
      images: '图片',
      created_at: '创建时间',
      category: '类别',
      status: '状态',
      moi: '排序',
      mri: '人工推荐值',
      ari: '系统推荐值',
      price: '价格',
      original_price: '原价',
      valid_from: '开始时间',
      valid_to: '结束时间',
      fee_amount: '手续费金额',
      fee_ratio: '手续费比例',
      remark: '备注',
      title: '标题',
      content: '内容'
    },
    user: {
      _: '用户',
      name: '账号',
      nickname: '昵称',
      password: '密码',
      mobile: '手机号',
      address: { _: '收货地址' }
    },
    visit: {
      _: '访问',
      signin: { _: '登录' },
      signup: { _: '注册' },
      vcode: { _: '验证码' }
    },
    payment: {
      _: '支付',
      category: '付款方式',
      created_at: '发起时间',
      paid_at: '付款时间',
      refund: {
        _: '退款',
        category: '退款方式',
        created_at: '发起时间',
        refunded_at: '退款时间'
      }
    },
    shop: {
      _: '商户',
      staff: { _: '人员' }
    },
    item: {
      _: '商品',
      statuses: {
        inactive: '待上架',
        active: '已上架',
        archived: '已下架',
        shop_inactive: '商户未认证'
      },
      sku: {
        _: 'SKU',
        qty: '库存'
      },
      group: { _: '品类' },
      grouping: { _: '分类' },
      stocking: { _: '出入库' },
      order: { _: '订单' },
      pack: { _: '订单包裹' },
      bill: {
        _: '订单结算',
        price: '订单金额',
        amount: '结算金额'
      },
      skuship: { _: '订单商品' }
    },
    m: {
      banner: { _: '首页轮播' },
      tile: { _: '首页磁砖' }
    },
    article: {
      _: '资讯文章',
      digest: '摘要'
    },
    notice: {
      _: '通知公告'
    },
    org: {
      _: '会员企业'
    }
  }
}

const conf = {
  customer_service: {
    email: 'jun-cs@cnzjrh.com',
    wechat_oap_name: '中军融合'
  }
}

const h = {
  img(src: any, defaultSrc?: string) {
    return src && src.image_src || typeof src === 'string' && src || defaultSrc || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mO8fuPWfwAIoQOKdWAy+wAAAABJRU5ErkJggg=='
  },
  thumbnail(src: any) {
    return this.img(src, '/assets/icon/icon.png')
  },
  avatar(src: any) {
    return this.img(src, '/assets/avatar.svg')
  },
  padStart(obj: any, maxLength?: number, fillString?: string) {
    return (obj ? obj.toString() : '').padStart(maxLength || 2, fillString || (typeof obj === 'number' ? '0' : undefined))
  }
}

const l = {
  rmb(fen: number) {
    if (fen === null) {
      return ''
    }
    fen = Math.round(fen)
    let remainder = Math.abs(fen)
    const quotient = Math.trunc(remainder / 100)
    remainder %= 100
    return (fen < 0 ? '-' : '') + '¥' + quotient + (remainder > 0 ? ('.' + Math.trunc(remainder / 10) + ((remainder %= 10) > 0 ? remainder : '')) : '')
  },
  datetime(s: string, format?: string) {
    const date = new Date(s)
    if (format === 'short') return `${date.getMonth() + 1}月${date.getDate()}日 ${h.padStart(date.getHours())}:${h.padStart(date.getMinutes())}`
    else if (format === 'date') return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    else if (format === 'hhmm') return `${h.padStart(date.getHours())}:${h.padStart(date.getMinutes())}`
    else return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${h.padStart(date.getHours())}:${h.padStart(date.getMinutes())}:${h.padStart(date.getSeconds())}`
  },
  bool(b: any) {
    return b ? i18n.true : i18n.false
  },
  score(score: any, key?: string) {
    if (!key) key = 'score'
    if (score && Object.prototype.hasOwnProperty.call(score, key)) score = score[key]
    if (!['number', 'string'].includes(typeof score)) score = 0
    return `${score || 0}分`
  },
  fullScore(fullScore: any) {
    return this.score(fullScore, 'full_score')
  },
  optionScore(score: any) {
    if (score && Object.prototype.hasOwnProperty.call(score, 'score')) score = score.score
    if (!['number', 'string'].includes(typeof score)) score = 0
    return score >= -100 ? `${score}分` : '一票否决'
  }
}

const SIGNED_IN_KEY = 'signed_in'
const session = {
  signedIn: ref(localStorage.getItem(SIGNED_IN_KEY) === 'true'),
  signIn() {
    this.signedIn.value = true
    localStorage.setItem(SIGNED_IN_KEY, 'true')
  },
  signOut() {
    this.signedIn.value = false
    localStorage.clear()
    sessionStorage.clear()
  }
}

const portal = function (): string {
  return location.pathname.split('/')[1]
}
const portalName = function (otherPortal?: string) {
  switch (otherPortal || portal()) {
    case 'op':
      return i18n.portals.op
    case 'serp':
      return i18n.portals.serp
    default:
      return i18n.portals.mbr
  }
}

import { toastController, alertController } from '@ionic/vue'
const toast = (msg: string) => {
  toastController.create({
    message: msg,
    duration: 3000
  }).then((toast) => toast.present())
}
const alert = (msg: string, fn?: { (): void }) => {
  alertController.create({
    header: i18n.app.name,
    message: msg,
    buttons: [i18n.ok]
  }).then((alert) => {
    alert.present()
    fn && alert.onDidDismiss().then(() => fn())
  })
}
const confirm = (fn: { (): void }, msg?: string) => {
  alertController.create({
    header: i18n.app.name,
    message: msg || i18n.confirmTip,
    buttons: [i18n.cancel, {
      text: i18n.ok,
      handler: fn
    }],
  }).then((alert) => alert.present())
}

class Observer {
  queue: { [key: string]: ((data: any) => any)[] };

  constructor() {
    this.queue = {}
  }

  on(eventName: string, fn: (data: any) => any) {
    this.queue[eventName] = this.queue[eventName] || []
    this.queue[eventName].push(fn)
  }

  off(eventName: string, fn: (data: any) => any) {
    if (this.queue[eventName]) {
      for (let i = 0; i < this.queue[eventName].length; i++) {
        if (this.queue[eventName][i] === fn) {
          this.queue[eventName].splice(i, 1)
          break
        }
      }
    }
  }

  emit(eventName: string, data: any) {
    if (this.queue[eventName]) this.queue[eventName].forEach((fn: (data: any) => any) => fn(data))
  }
}
const observer = new Observer()

import axios from './axios'
import wechat from './wechat'

export {
  session,
  h, l, i18n, conf,
  portal, portalName,
  toast, alert, confirm,
  observer,
  axios, wechat
}
