import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_split_pane, { "content-id": "main-content" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_menu, {
            "menu-id": "first",
            "content-id": "main-content",
            disabled: !_ctx.session.signedIn
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_toolbar, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_buttons, { slot: "start" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_menu_toggle, { menu: "first" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: "menu" })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_title, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_chip, {
                            color: "primary",
                            "router-link": "/serp/home"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_avatar, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_img, { src: "/assets/icon/icon.png" })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.i18n.app.name), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_menu_toggle, {
                        menu: "first",
                        "auto-hide": "false"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            button: "",
                            detail: "",
                            "router-link": "/serp/shops"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                icon: "list",
                                slot: "start"
                              }),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.i18n.models.shop._), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_menu_toggle, {
                        menu: "first",
                        "auto-hide": "false"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            button: "",
                            detail: "",
                            "router-link": "/serp/items?status=active"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                icon: "list",
                                slot: "start"
                              }),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.i18n.models.item._), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_menu_toggle, {
                        menu: "first",
                        "auto-hide": "false"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            button: "",
                            detail: "",
                            "router-link": "/serp/item/packs?status=shippable"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                icon: "list",
                                slot: "start"
                              }),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.i18n.models.item.pack._), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_menu_toggle, {
                        menu: "first",
                        "auto-hide": "false"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            button: "",
                            detail: "",
                            "router-link": "/serp/item/bills?status=unpaid"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                icon: "list",
                                slot: "start"
                              }),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.i18n.models.item.bill._), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["disabled"]),
          _createVNode(_component_ion_router_outlet, { id: "main-content" })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_spinner, { color: "primary" })
    ]),
    _: 1
  }))
}