<template>
  <ion-app>
    <ion-router-outlet />

    <ion-spinner color="primary"></ion-spinner>
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet, IonSpinner } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    IonApp,
    IonRouterOutlet,
    IonSpinner
  }
})
</script>

<style scoped>
</style>
