<template>
  <ion-input :placeholder="i18n.inputPrompt" type="number" min="0" step="0.01" v-model="fen">
    <input type="hidden" :name="name" :value="fen || fen === 0 ? Math.round(fen * 100) : null">
  </ion-input>
</template>

<script lang="ts">
import { defineComponent, ref, toRef, watch } from 'vue'

export default defineComponent({
  props: {
    name: String,
    value: Number
  },
  setup(props) {
    const value = toRef(props, 'value'), fen = ref(null as null | number)
    watch(value, (newValue) => {
      if (newValue || newValue === 0) {
        fen.value = newValue / 100
      }
    })
    return {
      fen
    }
  }
})
</script>

<style scoped>
</style>
