declare global {
  interface Array<T> {
    deleteIf(fn: (t: T, i: number) => boolean): Array<T>;
    delete(t: T): Array<T>;
    groupByProp(prop: string): Record<string, Array<T>>;
  }
}

if (!Array.prototype.deleteIf) {
  Array.prototype.deleteIf = function <T>(fn: (t: T, i: number) => boolean): Array<T> {
    let i = this.length
    while (i--) {
      fn(this[i], i) && this.splice(i, 1)
    }
    return this
  }
}
if (!Array.prototype.delete) {
  Array.prototype.delete = function <T>(t: T): Array<T> {
    return this.deleteIf((item) => item === t)
  }
}
if (!Array.prototype.groupByProp) {
  Array.prototype.groupByProp = function <T>(prop: string): Record<string, Array<T>> {
    return this.reduce((acc, cur) => {
      if (!acc[cur[prop]]) acc[cur[prop]] = []
      acc[cur[prop]].push(cur)
      return acc
    }, {})
  }
}

export {}
