<template>
  <ion-app>
    <ion-split-pane content-id="main-content">
      <ion-menu menu-id="first" content-id="main-content" :disabled="!session.signedIn">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-menu-toggle menu="first">
                <ion-button>
                  <ion-icon icon="menu"></ion-icon>
                </ion-button>
              </ion-menu-toggle>
            </ion-buttons>
            <ion-title>
              <ion-chip color="primary" router-link="/op/home">
                <ion-avatar>
                  <ion-img src="/assets/icon/icon.png"></ion-img>
                </ion-avatar>
                <ion-label>{{ i18n.app.name }}</ion-label>
              </ion-chip>
            </ion-title>
          </ion-toolbar>
        </ion-header>

        <ion-content>
          <ion-list>
            <ion-menu-toggle menu="first" auto-hide="false">
              <ion-item button detail router-link="/op/shops">
                <ion-icon icon="list" slot="start"></ion-icon>
                <ion-label>{{ i18n.models.shop._ }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle menu="first" auto-hide="false">
              <ion-item button detail router-link="/op/item/groups">
                <ion-icon icon="list" slot="start"></ion-icon>
                <ion-label>{{ i18n.models.item.group._ }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle menu="first" auto-hide="false">
              <ion-item button detail router-link="/op/items?status=active">
                <ion-icon icon="list" slot="start"></ion-icon>
                <ion-label>{{ i18n.models.item._ }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle menu="first" auto-hide="false">
              <ion-item button detail router-link="/op/item/orders?status=paid">
                <ion-icon icon="list" slot="start"></ion-icon>
                <ion-label>{{ i18n.models.item.order._ }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle menu="first" auto-hide="false">
              <ion-item button detail router-link="/op/item/bills?status=unpaid">
                <ion-icon icon="list" slot="start"></ion-icon>
                <ion-label>{{ i18n.models.item.bill._ }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle menu="first" auto-hide="false">
              <ion-item button detail router-link="/op/m/banners">
                <ion-icon icon="list" slot="start"></ion-icon>
                <ion-label>{{ i18n.models.m.banner._ }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle menu="first" auto-hide="false">
              <ion-item button detail router-link="/op/m/tiles">
                <ion-icon icon="list" slot="start"></ion-icon>
                <ion-label>{{ i18n.models.m.tile._ }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle menu="first" auto-hide="false">
              <ion-item button detail router-link="/op/articles">
                <ion-icon icon="list" slot="start"></ion-icon>
                <ion-label>{{ i18n.models.article._ }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle menu="first" auto-hide="false">
              <ion-item button detail router-link="/op/notices">
                <ion-icon icon="list" slot="start"></ion-icon>
                <ion-label>{{ i18n.models.notice._ }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-content>
      </ion-menu>

      <ion-router-outlet id="main-content"/>
    </ion-split-pane>

    <ion-spinner color="primary"></ion-spinner>
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet, IonSpinner, IonSplitPane } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    IonApp,
    IonRouterOutlet,
    IonSpinner,
    IonSplitPane
  }
})
</script>

<style scoped>
ion-split-pane {
  --side-width: 200px;
  --side-min-width: 0;
}
</style>
