import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  { path: '/', name: 'root', redirect: '/ow/welcome' },
  { path: '/not_found', name: 'not_found', component: () => import('@/views/not_found.vue') },
  { path: '/unauthorized', name: 'unauthorized', component: () => import('@/views/unauthorized.vue') },
  { path: '/terms_of_use', name: 'terms_of_use', component: () => import('@/views/terms_of_use.vue') },
  { path: '/privacy_policy', name: 'privacy_policy', component: () => import('@/views/privacy_policy.vue') },
  { path: '/wechat/oap/auth', name: 'wechat-oap-auth', component: () => import('@/views/wechat/oap/auth.vue') },

  { path: '/mbr', name: 'mbr', redirect: '/mbr/welcome' },
  { path: '/mbr/welcome', name: 'mbr-welcome', component: () => import('@/views/mbr/welcome/index.vue') },
  { path: '/mbr/welcome/invite', name: 'mbr-welcome-invite', component: () => import('@/views/mbr/welcome/invite.vue') },
  { path: '/mbr/welcome/customer_service', name: 'mbr-welcome-customer_service', component: () => import('@/views/mbr/welcome/customer_service.vue') },
  { path: '/mbr/signin', name: 'mbr-signin', component: () => import('@/views/mbr/welcome/signin.vue') },
  { path: '/mbr/signup', name: 'mbr-signup', component: () => import('@/views/mbr/welcome/signup.vue') },
  { path: '/mbr/home', name: 'mbr-home', redirect: '/mbr/welcome' },
  { path: '/mbr/home/me', name: 'mbr-home-me', component: () => import('@/views/mbr/home/me.vue') },
  { path: '/mbr/user', name: 'mbr-user-show', component: () => import('@/views/mbr/user/show.vue') },
  { path: '/mbr/user/edit', name: 'mbr-user-edit', component: () => import('@/views/mbr/user/edit.vue') },
  { path: '/mbr/items', name: 'mbr-items-index', component: () => import('@/views/mbr/items/index.vue') },
  { path: '/mbr/items/none', name: 'mbr-items-none', component: () => import('@/views/mbr/items/none.vue') },
  { path: '/mbr/items/:id', name: 'mbr-items-show', component: () => import('@/views/mbr/items/show.vue') },
  { path: '/mbr/item/skuings', name: 'mbr-item-skuings-index', component: () => import('@/views/mbr/item/skuings/index.vue') },
  { path: '/mbr/item/orders', name: 'mbr-item-orders-index', component: () => import('@/views/mbr/item/orders/index.vue') },
  { path: '/mbr/item/orders/new', name: 'mbr-item-orders-new', component: () => import('@/views/mbr/item/orders/new.vue') },
  { path: '/mbr/item/orders/:id', name: 'mbr-item-orders-show', component: () => import('@/views/mbr/item/orders/show.vue') },
  { path: '/mbr/item/groups', name: 'mbr-item-groups-index', component: () => import('@/views/mbr/item/groups/index.vue') },
  { path: '/mbr/user/addresses', name: 'mbr-user-addresses-index', component: () => import('@/views/mbr/user/addresses/index.vue') },
  { path: '/mbr/user/addresses/select', name: 'mbr-user-addresses-select', component: () => import('@/views/mbr/user/addresses/select.vue') },
  { path: '/mbr/user/addresses/new', name: 'mbr-user-addresses-new', component: () => import('@/views/mbr/user/addresses/new.vue') },
  { path: '/mbr/user/addresses/:id/edit', name: 'mbr-user-addresses-edit', component: () => import('@/views/mbr/user/addresses/edit.vue') },
  { path: '/mbr/payments/new', name: 'mbr-payments-new', component: () => import('@/views/mbr/payments/new.vue') },
  { path: '/mbr/payments/:id', name: 'mbr-payments-show', component: () => import('@/views/mbr/payments/show.vue') },

  { path: '/op', name: 'op', redirect: '/op/home' },
  { path: '/op/welcome', name: 'op-welcome', redirect: '/op/signin' },
  { path: '/op/signin', name: 'op-signin', component: () => import('@/views/op/welcome/signin.vue') },
  { path: '/op/home', name: 'op-home-index', component: () => import('@/views/op/home/index.vue') },
  { path: '/op/user', name: 'op-user-show', component: () => import('@/views/op/user/show.vue') },
  { path: '/op/user/edit', name: 'op-user-edit', component: () => import('@/views/op/user/edit.vue') },
  { path: '/op/m/banners', name: 'op-m-banners-index', component: () => import('@/views/op/m/banners/index.vue') },
  { path: '/op/m/banners/new', name: 'op-m-banners-new', component: () => import('@/views/op/m/banners/new.vue') },
  { path: '/op/m/banners/:id/edit', name: 'op-m-banners-edit', component: () => import('@/views/op/m/banners/edit.vue') },
  { path: '/op/m/tiles', name: 'op-m-tiles-index', component: () => import('@/views/op/m/tiles/index.vue') },
  { path: '/op/m/tiles/new', name: 'op-m-tiles-new', component: () => import('@/views/op/m/tiles/new.vue') },
  { path: '/op/m/tiles/:id/edit', name: 'op-m-tiles-edit', component: () => import('@/views/op/m/tiles/edit.vue') },
  { path: '/op/shops', name: 'op-shops-index', component: () => import('@/views/op/shops/index.vue') },
  { path: '/op/shops/new', name: 'op-shops-new', component: () => import('@/views/op/shops/new.vue') },
  { path: '/op/shops/:id', name: 'op-shops-show', component: () => import('@/views/op/shops/show.vue') },
  { path: '/op/shops/:id/edit', name: 'op-shops-edit', component: () => import('@/views/op/shops/edit.vue') },
  { path: '/op/shops/:shop_id/staffs/new', name: 'op-shop-staffs-new', component: () => import('@/views/op/shop/staffs/new.vue') },
  { path: '/op/shop/staffs/:id/edit', name: 'op-shop-staffs-edit', component: () => import('@/views/op/shop/staffs/edit.vue') },
  { path: '/op/items', name: 'op-items-index', component: () => import('@/views/op/items/index.vue') },
  { path: '/op/items/new', name: 'op-items-new', component: () => import('@/views/op/items/new.vue') },
  { path: '/op/items/:id', name: 'op-items-show', component: () => import('@/views/op/items/show.vue') },
  { path: '/op/items/:id/edit', name: 'op-items-edit', component: () => import('@/views/op/items/edit.vue') },
  { path: '/op/items/:item_id/skus/new', name: 'op-item-skus-new', component: () => import('@/views/op/item/skus/new.vue') },
  { path: '/op/item/skus/:id', name: 'op-item-skus-show', component: () => import('@/views/op/item/skus/show.vue') },
  { path: '/op/item/skus/:id/edit', name: 'op-item-skus-edit', component: () => import('@/views/op/item/skus/edit.vue') },
  { path: '/op/item/skus/:sku_id/stockings/new', name: 'op-item-stockings-new', component: () => import('@/views/op/item/stockings/new.vue') },
  { path: '/op/item/groups', name: 'op-item-groups-index', component: () => import('@/views/op/item/groups/index.vue') },
  { path: '/op/item/groups/new', name: 'op-item-groups-new', component: () => import('@/views/op/item/groups/new.vue') },
  { path: '/op/item/groups/:id/edit', name: 'op-item-groups-edit', component: () => import('@/views/op/item/groups/edit.vue') },
  { path: '/op/items/:item_id/groupings/new', name: 'op-item-groupings-new', component: () => import('@/views/op/item/groupings/new.vue') },
  { path: '/op/item/orders', name: 'op-item-orders-index', component: () => import('@/views/op/item/orders/index.vue') },
  { path: '/op/item/orders/:id', name: 'op-item-orders-show', component: () => import('@/views/op/item/orders/show.vue') },
  { path: '/op/item/packs/:id', name: 'op-item-packs-show', component: () => import('@/views/op/item/packs/show.vue') },
  { path: '/op/item/bills', name: 'op-item-bills-index', component: () => import('@/views/op/item/bills/index.vue') },
  { path: '/op/item/bills/:id', name: 'op-item-bills-show', component: () => import('@/views/op/item/bills/show.vue') },
  { path: '/op/item/bills/:id/edit', name: 'op-item-bills-edit', component: () => import('@/views/op/item/bills/edit.vue') },
  { path: '/op/articles', name: 'op-articles-index', component: () => import('@/views/op/articles/index.vue') },
  { path: '/op/articles/new', name: 'op-articles-new', component: () => import('@/views/op/articles/new.vue') },
  { path: '/op/articles/:id', name: 'op-articles-show', component: () => import('@/views/op/articles/show.vue') },
  { path: '/op/articles/:id/edit', name: 'op-articles-edit', component: () => import('@/views/op/articles/edit.vue') },
  { path: '/op/notices', name: 'op-notices-index', component: () => import('@/views/op/notices/index.vue') },
  { path: '/op/notices/new', name: 'op-notices-new', component: () => import('@/views/op/notices/new.vue') },
  { path: '/op/notices/:id', name: 'op-notices-show', component: () => import('@/views/op/notices/show.vue') },
  { path: '/op/notices/:id/edit', name: 'op-notices-edit', component: () => import('@/views/op/notices/edit.vue') },

  { path: '/serp', name: 'serp', redirect: '/serp/home' },
  { path: '/serp/welcome', name: 'serp-welcome', redirect: '/serp/signin' },
  { path: '/serp/signin', name: 'serp-signin', component: () => import('@/views/serp/welcome/signin.vue') },
  { path: '/serp/home', name: 'serp-home-index', component: () => import('@/views/serp/home/index.vue') },
  { path: '/serp/user', name: 'serp-user-show', component: () => import('@/views/serp/user/show.vue') },
  { path: '/serp/user/edit', name: 'serp-user-edit', component: () => import('@/views/serp/user/edit.vue') },
  { path: '/serp/shops', name: 'serp-shops-index', component: () => import('@/views/serp/shops/index.vue') },
  { path: '/serp/shops/:id', name: 'serp-shops-show', component: () => import('@/views/serp/shops/show.vue') },
  { path: '/serp/shops/:shop_id/staffs/new', name: 'serp-shop-staffs-new', component: () => import('@/views/serp/shop/staffs/new.vue') },
  { path: '/serp/shop/staffs/:id/edit', name: 'serp-shop-staffs-edit', component: () => import('@/views/serp/shop/staffs/edit.vue') },
  { path: '/serp/items', name: 'serp-items-index', component: () => import('@/views/serp/items/index.vue') },
  { path: '/serp/items/new', name: 'serp-items-new', component: () => import('@/views/serp/items/new.vue') },
  { path: '/serp/items/:id', name: 'serp-items-show', component: () => import('@/views/serp/items/show.vue') },
  { path: '/serp/items/:id/edit', name: 'serp-items-edit', component: () => import('@/views/serp/items/edit.vue') },
  { path: '/serp/items/:item_id/skus/new', name: 'serp-item-skus-new', component: () => import('@/views/serp/item/skus/new.vue') },
  { path: '/serp/item/skus/:id', name: 'serp-item-skus-show', component: () => import('@/views/serp/item/skus/show.vue') },
  { path: '/serp/item/skus/:id/edit', name: 'serp-item-skus-edit', component: () => import('@/views/serp/item/skus/edit.vue') },
  { path: '/serp/item/skus/:sku_id/stockings/new', name: 'serp-item-stockings-new', component: () => import('@/views/serp/item/stockings/new.vue') },
  { path: '/serp/item/packs', name: 'serp-item-packs-index', component: () => import('@/views/serp/item/packs/index.vue') },
  { path: '/serp/item/packs/:id', name: 'serp-item-packs-show', component: () => import('@/views/serp/item/packs/show.vue') },
  { path: '/serp/item/packs/:id/edit', name: 'serp-item-packs-edit', component: () => import('@/views/serp/item/packs/edit.vue') },
  { path: '/serp/item/bills', name: 'serp-item-bills-index', component: () => import('@/views/serp/item/bills/index.vue') },
  { path: '/serp/item/bills/new', name: 'serp-item-bills-new', component: () => import('@/views/serp/item/bills/new.vue') },
  { path: '/serp/item/bills/:id', name: 'serp-item-bills-show', component: () => import('@/views/serp/item/bills/show.vue') },

  { path: '/ow', name: 'ow', redirect: '/ow/welcome' },
  { path: '/ow/welcome', name: 'ow-welcome', component: () => import('@/views/ow/welcome/index.vue') },
  { path: '/ow/welcome/about', name: 'ow-welcome-about', component: () => import('@/views/ow/welcome/about.vue') },
  { path: '/ow/welcome/contact', name: 'ow-welcome-contact', component: () => import('@/views/ow/welcome/contact.vue') },
  { path: '/ow/welcome/magazine', name: 'ow-welcome-magazine', component: () => import('@/views/ow/welcome/magazine.vue') },
  { path: '/ow/welcome/trial', name: 'ow-welcome-trial', component: () => import('@/views/ow/welcome/trial.vue') },
  { path: '/ow/articles', name: 'ow-articles-index', component: () => import('@/views/ow/articles/index.vue') },
  { path: '/ow/articles/:id', name: 'ow-articles-show', component: () => import('@/views/ow/articles/show.vue') },
  { path: '/ow/notices', name: 'ow-notices-index', component: () => import('@/views/ow/notices/index.vue') },
  { path: '/ow/notices/:id', name: 'ow-notices-show', component: () => import('@/views/ow/notices/show.vue') },
  { path: '/ow/items', name: 'ow-items-index', component: () => import('@/views/ow/items/index.vue') },
  { path: '/ow/items/:id', name: 'ow-items-show', component: () => import('@/views/ow/items/show.vue') },
  { path: '/ow/orgs', name: 'ow-orgs-index', component: () => import('@/views/ow/orgs/index.vue') },
  { path: '/ow/orgs/:id', name: 'ow-orgs-show', component: () => import('@/views/ow/orgs/show.vue') },

  { path: '/:pathMatch(.*)*', redirect: { name: 'not_found' } }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
