import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, { translucent: true }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_buttons, { slot: "start" }, {
            default: _withCtx(() => [
              (_ctx.backTo)
                ? (_openBlock(), _createBlock(_component_ion_back_button, {
                    key: 0,
                    "default-href": _ctx.backTo
                  }, null, 8, ["default-href"]))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_menu_toggle, { menu: "first" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: "menu" })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.pt), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_buttons, { slot: "end" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                title: _ctx.i18n.profile,
                "router-link": "/op/user"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: "person" })
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_ion_button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.signOut())),
                title: _ctx.i18n.sign_out
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: "exit",
                    slot: "start"
                  })
                ]),
                _: 1
              }, 8, ["title"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}