<template>
  <ion-header :translucent="true">
    <ion-toolbar>
      <ion-grid fixed>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-thumbnail class="ion-margin-vertical" router-link="/ow">
              <ion-img src="/assets/icon/icon.png"></ion-img>
            </ion-thumbnail>
          </ion-buttons>
          <ion-title>
            <div class="xx-large bolder">{{ pt }}</div>
          </ion-title>
          <ion-buttons slot="end">
            <ion-searchbar :placeholder="i18n.search" class="ion-hide-lg-down"></ion-searchbar>
            <ion-button color="primary" fill="solid" router-link="/">{{ i18n.sign_in }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-grid>
    </ion-toolbar>
    <ion-toolbar color="primary">
      <ion-grid fixed class="ion-no-padding">
        <ion-row>
          <ion-col>
            <ion-button expand="block" fill="clear" router-link="/ow/welcome" :data-current-menu="currentMenu === 'welcome'">
              <div>{{ i18n.home }}</div>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button expand="block" fill="clear" router-link="/ow/notices" :data-current-menu="currentMenu === 'notices'">
              <div>{{ i18n.m('notice') }}</div>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button expand="block" fill="clear" router-link="/ow/articles" :data-current-menu="currentMenu === 'articles'">
              <div>{{ i18n.m('article') }}</div>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button expand="block" fill="clear" router-link="/ow/items" :data-current-menu="currentMenu === 'items'">
              <div>{{ i18n.mall }}</div>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button expand="block" fill="clear" router-link="/ow/orgs" :data-current-menu="currentMenu === 'orgs'">
              <div>{{ i18n.m('org') }}</div>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button expand="block" fill="clear" router-link="/ow/welcome/magazine" :data-current-menu="currentMenu === 'magazine'">
              <div>{{ i18n.magazine }}</div>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button expand="block" fill="clear" router-link="/ow/welcome/about" :data-current-menu="currentMenu === 'about'">
              <div>{{ i18n.about }}</div>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button expand="block" fill="clear" router-link="/ow/welcome/contact" :data-current-menu="currentMenu === 'contact'">
              <div>{{ i18n.contact }}</div>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { i18n } from '@/h'

export default defineComponent({
  props: {
    pt: {
      type: String,
      default: i18n.app.name
    },
    backTo: {
      type: String,
      default: '/ow'
    }
  },
  setup() {
    const currentMenu = ref('welcome')
    const route = useRoute()

    switch (route.path) {
      case '/ow/welcome/about':
        currentMenu.value = 'about'
        break;
      case '/ow/welcome/contact':
        currentMenu.value = 'contact'
        break;
      case '/ow/welcome/magazine':
      case '/ow/welcome/trial':
        currentMenu.value = 'magazine'
        break;
      default:
        if (route.path.startsWith('/ow/notices')) {
          currentMenu.value = 'notices'
        } else if (route.path.startsWith('/ow/articles')) {
          currentMenu.value = 'articles'
        } else if (route.path.startsWith('/ow/items')) {
          currentMenu.value = 'items'
        } else if (route.path.startsWith('/ow/orgs')) {
          currentMenu.value = 'orgs'
        } else {
          currentMenu.value = 'welcome'
        }
        break;
    }

    return {
      currentMenu
    }
  }
})
</script>

<style scoped>
[data-current-menu] {
  color: var(--ion-color-primary-contrast);
}
[data-current-menu="true"] > div {
  padding: .5em 0;
  border-bottom: 2px solid var(--ion-color-primary-contrast);
}
</style>
