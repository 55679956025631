import axios from '@/h/axios'

declare global {
  interface Window {
    wx: any;
  }
}

const wechat = {
  oap: {
    fromWechat() {
      return /MicroMessenger/i.test(navigator.userAgent)
    },
    jsapiPresent() {
      return typeof window.wx !== 'undefined'
    },
    ready(fn: () => any, efn?: () => any) {
      if (!this.fromWechat()) efn && efn.call(this)
      else if (this.jsapiPresent()) fn && fn.call(this)
      else {
        const script = document.createElement('script')
        script.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js'
        script.async = false
        script.onload = () => axios.get('/wechat/oap/jsapi_config', {
          params: { url: location.href }
        }).then((response) => this.configure(response.data.config, fn, efn)).catch(() => efn && efn.call(this))
        script.onerror = () => efn && efn.call(this)
        document.body.append(script)
      }
    },
    configure(config: any, fn?: () => any, efn?: () => any) {
      window.wx.config(Object.assign(config, {
        jsApiList: [
          'updateAppMessageShareData', 'updateTimelineShareData',
          'showAllNonBaseMenuItem', 'hideAllNonBaseMenuItem',
          'showMenuItems', 'hideMenuItems',
          'chooseWXPay'
        ]
      }))
      window.wx.ready(() => {
        window.wx.hideAllNonBaseMenuItem()
        window.wx.showMenuItems({
          menuList: [
            'menuItem:profile', 'menuItem:addContact', 'menuItem:openWithSafari'
          ]
        })
        fn && fn.call(this)
      })
      window.wx.error(() => efn && efn.call(this))
    },
    share(data?: any) {
      this.ready(() => {
        if (!data) data = {}
        const descriptionEl = document.querySelector('meta[name="description"]') as any
        const imageEl = document.querySelector('link[rel="apple-touch-icon"]') as any
        const shareData = {
          title: data.title || document.title,
          desc: data.description || (descriptionEl ? descriptionEl.content : document.title),
          link: data.link || location.href,
          imgUrl: data.image || (imageEl ? imageEl.href : null)
          // success: () => {}
        }
        window.wx.updateAppMessageShareData(shareData)
        window.wx.updateTimelineShareData(shareData)
        window.wx.showMenuItems({
          menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline', 'menuItem:share:qq', 'menuItem:share:QZone', 'menuItem:favorite']
        })
      })
    },
    pay(config: any) {
      this.ready(() => window.wx.chooseWXPay(config))
    }
  }
}

export default wechat
